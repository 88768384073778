// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sk-category-videos-js": () => import("./../../../src/pages/sk/categoryVideos.js" /* webpackChunkName: "component---src-pages-sk-category-videos-js" */),
  "component---src-pages-sk-index-js": () => import("./../../../src/pages/sk/index.js" /* webpackChunkName: "component---src-pages-sk-index-js" */),
  "component---src-pages-sk-live-js": () => import("./../../../src/pages/sk/live.js" /* webpackChunkName: "component---src-pages-sk-live-js" */),
  "component---src-pages-sk-najnovsie-js": () => import("./../../../src/pages/sk/najnovsie.js" /* webpackChunkName: "component---src-pages-sk-najnovsie-js" */),
  "component---src-pages-sk-najsledovanejsie-js": () => import("./../../../src/pages/sk/najsledovanejsie.js" /* webpackChunkName: "component---src-pages-sk-najsledovanejsie-js" */),
  "component---src-pages-sk-tagged-videos-js": () => import("./../../../src/pages/sk/taggedVideos.js" /* webpackChunkName: "component---src-pages-sk-tagged-videos-js" */),
  "component---src-pages-sk-video-js": () => import("./../../../src/pages/sk/video.js" /* webpackChunkName: "component---src-pages-sk-video-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */)
}

